import { DEFAULT_TEAM_TRIAL_LENGTH_IN_DAYS } from '@arcadehq/shared/constants'
import { getPlan, Plan } from '@arcadehq/shared/helpers'
import { extractGroupName } from '@arcadehq/shared/helpers/groups'
import type { TeamPrefs } from '@arcadehq/shared/types'
import type { Timestamp } from 'firebase/firestore'
import type { AuthUser } from 'next-firebase-auth'
import { Flow, Team, UserProfile } from 'src/types'
import { isFlowLocked } from 'src/utils/subscription'

export type { Plan } from '@arcadehq/shared/helpers'

export type AccountProtectedFields = {
  authUser: AuthUser
  userProfile: UserProfile
  team: Team | null
  plan: Plan
}

export type AccountMixin<T> = (
  core: AccountCore,
  fields: AccountProtectedFields,
  ...args: any
) => T

export type TrialProgress =
  | {
      trialActive: true
      daysTotal: number
      daysUsed: number
      daysLeft: number
      upgradeRequested: boolean
    }
  | {
      trialActive: false
      teamExists: boolean
    }

const normalizeDate = (date: string | Date | Timestamp) => {
  if (typeof date === 'object' && 'seconds' in date) {
    return new Date(date.seconds * 1000)
  }
  return new Date(date)
}

export class AccountCore {
  protected plan: Plan

  constructor(
    protected authUser: AuthUser,
    protected userProfile: UserProfile,
    protected team: Team | null
  ) {
    this.plan = getPlan(userProfile, team)
  }

  get isLoggedIn(): boolean {
    return !!this.authUser.id
  }

  logOut(): Promise<void> {
    return this.authUser.signOut()
  }

  get userId(): string {
    return this.authUser.id ?? ''
  }

  get userEmail(): string | null {
    return this.authUser.email
  }

  get userEmailGroup(): string | null {
    return extractGroupName({ id: this.userId, email: this.userEmail })
  }

  get userProfileGroup(): string | null {
    return this.userProfile.group ?? null
  }

  get userEmailVerified(): boolean {
    return this.authUser.emailVerified
  }

  get userName(): string | null {
    return this.authUser.displayName
  }

  get userFirstName(): string | null {
    return this.authUser.displayName?.split(' ')[0] || null
  }

  get userPhotoUrl(): string | null {
    return this.userProfile.photoURL ?? null
  }

  get needsOnboardingForm(): boolean {
    if (
      this.userProfile?.id &&
      !this.userProfile.onboardingCompanySize &&
      !this.userProfile.onboardingUseCases
    ) {
      const now = new Date()
      const lastSignInTime = this.firebaseUser?.metadata.lastSignInTime
        ? new Date(this.firebaseUser?.metadata.lastSignInTime)
        : now
      const userCreatedDate = new Date(
        this.firebaseUser?.metadata.creationTime ?? this.userProfile.created
      )
      const cutoffDate = new Date('2023-06-22T17:00:00.000Z') // date of the onboarding form release
      if (
        userCreatedDate > cutoffDate ||
        // The following conditions are to guard against showing the onboarding for users who slipped
        // through the onboarding form. We don't want to show the form to users who have already been using
        // the product.
        !this.userProfile.isActiveMemberOfTeam ||
        !this.userProfile.activeTeamId ||
        lastSignInTime > now
      ) {
        return true
      }
    }
    return false
  }

  getUserIdToken(forceRefresh: boolean = false): Promise<string | null> {
    return this.authUser.getIdToken(forceRefresh)
  }

  async fetchWithToken(
    method: 'GET' | 'POST',
    apiPath: string,
    requestBody?: any
  ): Promise<Response> {
    const idToken = await this.getUserIdToken()
    return fetch(apiPath, {
      method,
      headers: {
        Authorization: idToken!,
        'Content-Type': 'application/json',
      },
      ...(requestBody
        ? {
            body: JSON.stringify(requestBody),
          }
        : {}),
    })
  }

  get firebaseUser(): AuthUser['firebaseUser'] {
    return this.authUser.firebaseUser
  }

  get isClientInitialized(): boolean {
    return this.authUser.clientInitialized
  }

  // TODO account for deprovisioned state
  get teamIsTrialing(): boolean {
    return this.plan === 'Growth Trial'
  }

  get teamIsDeprovisioned(): boolean {
    return !!this.team && !this.team.currentSubscriber && !this.team.customerId
  }

  // TODO fold this into something else
  get previouslyHadProSubscription(): boolean {
    return !!this.userProfile.customerId && !this.userProfile.currentSubscriber
  }

  // TODO fold this into something else
  get hasProSubscription(): boolean {
    return !!this.userProfile.customerId && !!this.userProfile.currentSubscriber
  }

  // TODO fold this into something else
  get hasNeverHadProSubscription(): boolean {
    return !this.userProfile.customerId && !this.userProfile.currentSubscriber
  }

  // TODO break this out into entitlements
  get isActiveMemberOfTeam(): boolean {
    return !!this.userProfile.isActiveMemberOfTeam
  }

  // TODO reconcile the following two methods
  get isExternalMemberOfTeam(): boolean {
    return !!this.userProfile.isExternalMemberOfTeam
  }

  get isExternalUser(): boolean {
    return this.userEmailGroup === this.userEmail
  }

  // TODO determine whether this is ever different from team.id
  get teamGroup(): string | null {
    return this.team?.group ?? null
  }

  // TODO break out a .trial feature?
  get teamTrialProgress(): TrialProgress {
    if (!this.team || !this.teamIsTrialing) {
      return {
        trialActive: false,
        teamExists: !!this.team,
      }
    }

    const dayMs = 1000 * 60 * 60 * 24

    const teamCreatedMs = normalizeDate(this.team.created).getTime()
    const nowMs = Date.now()

    const daysSinceCreated = (nowMs - teamCreatedMs) / dayMs

    let trialLengthDays = DEFAULT_TEAM_TRIAL_LENGTH_IN_DAYS
    if (this.team.trialEndDateIso) {
      const trialEndDateMs = new Date(this.team.trialEndDateIso).getTime()
      trialLengthDays = (trialEndDateMs - teamCreatedMs) / dayMs
    }

    const trialDaysRemaining = Math.max(
      0,
      Math.ceil(trialLengthDays - daysSinceCreated)
    )

    // They're paying for pro, and the trial is expired, so they're not in a trial.
    if (trialDaysRemaining === 0 && this.hasProSubscription) {
      return {
        trialActive: false,
        teamExists: true,
      }
    }

    return {
      trialActive: true,
      daysTotal: trialLengthDays,
      daysUsed: daysSinceCreated,
      daysLeft: trialDaysRemaining,
      upgradeRequested: !!this.team.trialUpgradeRequested,
    }
  }

  get freePlanLimitsApply() {
    if (this.plan === 'Pro') {
      return this.isOverdue
    }

    // trialActive=true even when days left are 0
    if (this.plan === 'Growth Trial') {
      return (
        !this.teamTrialProgress.trialActive ||
        this.teamTrialProgress.daysLeft <= 0
      )
    }

    if (this.plan === 'Growth') {
      return this.isOverdue || this.teamIsDeprovisioned
    }

    return true
  }

  get mayEditVideo(): boolean {
    return this.plan === 'Growth' || this.plan === 'Growth Trial'
  }

  get mayInviteCollaborators(): boolean {
    return this.plan === 'Growth' || this.plan === 'Growth Trial'
  }

  mayEditFlow(flow: Flow): boolean {
    return (
      (!isFlowLocked(flow, this.freePlanLimitsApply) &&
        !!this.userId &&
        (flow.createdBy === this.userId ||
          flow.editors?.includes(this.userId))) ||
      (!this.team?.features?.['Edit Access to Arcades is Controlled'] &&
        flow.belongsToTeam &&
        this.isActiveMemberOfTeam &&
        this.teamGroup === flow.group)
    )
  }

  mayToggleFlowBelongsToTeam(flow: Flow): boolean {
    return this.isActiveMemberOfTeam && flow.createdBy === this.userId
  }

  mayDeleteFlow(flow: Flow): boolean {
    return this.userId === flow.createdBy
  }

  get mayUseURLDestinationInOverlay(): boolean {
    return !this.plan.startsWith('Free')
  }

  // TODO combine flowDefaults and hotspotDefaults and move this there
  get mayEditAppearanceSettings(): boolean {
    return (
      (this.plan === 'Growth' || this.plan === 'Growth Trial') &&
      !!this.userProfile.isTeamAdmin
    )
  }

  get mayManageTeam(): boolean {
    return (
      (this.plan === 'Growth' || this.plan === 'Growth Trial') &&
      !!this.userProfile.isTeamAdmin
    )
  }

  // TODO move to .privacy
  get mayEditAccessControl(): boolean {
    return (
      (this.plan === 'Growth' || this.plan === 'Growth Trial') &&
      !!this.userProfile.isTeamAdmin
    )
  }

  // TODO move to .privacy
  get mayEditPrivacySettings(): boolean {
    return (
      (this.plan === 'Growth' || this.plan === 'Growth Trial') &&
      !!this.userProfile.isTeamAdmin
    )
  }

  // TODO combine flowDefaults and hotspotDefaults and move this there
  get hasAppearanceSettings(): boolean {
    return !!this.team?.prefs
  }

  // TODO remove the reset button?
  // Dumb hack of firebase
  resetAppearanceSettings(deleteFieldValue: any): Promise<boolean> {
    if (!this.team) return Promise.resolve(false)

    return this.team.update(
      { prefs: deleteFieldValue as TeamPrefs },
      this.userId
    )
  }

  // TODO move to .trial
  get didDismissTrialBanner(): boolean {
    return this.userProfile?.dismissedGrowthTrialBanner ?? false
  }

  // TODO move to .trial
  dismissTrialBanner(): Promise<boolean> {
    if (!this.userProfile) return Promise.resolve(false)

    return this.userProfile.update(
      { dismissedGrowthTrialBanner: true },
      this.userId
    )
  }

  get mayViewTeamSettings(): boolean {
    return this.plan.startsWith('Growth')
  }

  get mayViewUserSettings(): boolean {
    return this.plan.startsWith('Free') || this.plan.startsWith('Pro')
  }

  get planName(): 'Free' | 'Pro' | 'Growth' {
    return this.plan.startsWith('Growth')
      ? 'Growth'
      : this.plan.startsWith('Pro')
      ? 'Pro'
      : 'Free'
  }

  get planDescription() {
    return this.plan
  }

  // Note: This currently disregards whether or not the user / team is a
  // current subscriber. It also ignores an overdue flag if the user is
  // trialing growth.
  get isOverdue(): boolean {
    return (
      !!(this.userProfile.isOverdue || this.team?.isOverdue) &&
      !this.teamIsTrialing
    )
  }

  get mayUseRemotion(): boolean {
    return !!this.team?.features?.['Remotion Export']
  }
}
