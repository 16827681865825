import { PublishStatus } from '@arcadehq/shared/lib/types'
import { Flow } from 'src/types'

// I couldn't put it in ./flows.ts because it was throwing an error
// TypeError: Class extends value undefined is not a constructor or null
export const isFlowLocked = (
  flow: Flow,
  limitApplies: boolean | null = false
) =>
  limitApplies
    ? !flow.unlocked && flow.status === PublishStatus.published
    : false
