"use strict";
exports.__esModule = true;
exports.getStepOrder = exports.sortHotspotsForAnimation = exports.getNextNonEmptyAutoStep = exports.getNextNonEmptyIndexedStep = exports.getFirstNonEmptyStep = exports.isCropped = exports.hasBlur = void 0;
var types_1 = require("../types");
function hasBlur(step) {
    var _a, _b;
    return (!!((_a = step.imageEditorState) === null || _a === void 0 ? void 0 : _a.redaction) &&
        ((_b = step.imageEditorState) === null || _b === void 0 ? void 0 : _b.redaction.length) > 0);
}
exports.hasBlur = hasBlur;
function isCropped(step) {
    var _a, _b, _c;
    if (!((_a = step.imageEditorState) === null || _a === void 0 ? void 0 : _a.crop) || !((_b = step.size) === null || _b === void 0 ? void 0 : _b.height) || !((_c = step.size) === null || _c === void 0 ? void 0 : _c.width)) {
        return false;
    }
    return (step.imageEditorState.crop.height !== step.size.height ||
        step.imageEditorState.crop.width !== step.size.width ||
        step.imageEditorState.crop.x !== 0 ||
        step.imageEditorState.crop.y !== 0);
}
exports.isCropped = isCropped;
function getFirstNonEmptyStep(steps) {
    return steps.find(function (step) { return step.type !== types_1.StepType.Empty; });
}
exports.getFirstNonEmptyStep = getFirstNonEmptyStep;
function getNextNonEmptyIndexedStep(steps, fromStep, allowRestart) {
    var _a;
    var fromStepIndex = steps.findIndex(function (s) { return s.id === fromStep.id; });
    return ((_a = getFirstNonEmptyStep(steps.slice(fromStepIndex + 1))) !== null && _a !== void 0 ? _a : (allowRestart ? getFirstNonEmptyStep(steps) : undefined));
}
exports.getNextNonEmptyIndexedStep = getNextNonEmptyIndexedStep;
function getNextNonEmptyAutoStep(steps, fromStep, allowRestart) {
    var _a, _b, _c, _d, _e;
    var nextAutoStepId;
    if (fromStep.type === types_1.StepType.Image ||
        (fromStep.type === types_1.StepType.Overlay &&
            (!fromStep.paths || !((_a = fromStep.paths) === null || _a === void 0 ? void 0 : _a.length)))) {
        nextAutoStepId = (_c = (_b = fromStep.hotspots) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.targetId;
    }
    else if (fromStep.type === types_1.StepType.Overlay && ((_d = fromStep.paths) === null || _d === void 0 ? void 0 : _d.length)) {
        nextAutoStepId = (_e = fromStep.paths.find(function (path) { return path.pathType === 'step'; })) === null || _e === void 0 ? void 0 : _e.targetStepId;
    }
    var nextAutoStep;
    if (nextAutoStepId) {
        nextAutoStep = steps.find(function (_a) {
            var id = _a.id;
            return id === nextAutoStepId;
        });
    }
    if (!nextAutoStep) {
        nextAutoStep = getNextNonEmptyIndexedStep(steps, fromStep, allowRestart);
    }
    return nextAutoStep;
}
exports.getNextNonEmptyAutoStep = getNextNonEmptyAutoStep;
// Sort hotspots/callouts so that they indexes attempt to match the type.
// This sort prioritizes hotspots over callouts so that hotspots are
// always animated.
// TODO: We could likely improve this even further by splitting hotspots and
// callouts entirely and then finding the closest ones of each type between
// steps.
function sortHotspotsForAnimation(a, b) {
    var _a, _b;
    return ((_a = a.style) !== null && _a !== void 0 ? _a : 'pulsating') > ((_b = b.style) !== null && _b !== void 0 ? _b : 'pulsating') ? -1 : 1;
}
exports.sortHotspotsForAnimation = sortHotspotsForAnimation;
// Returns an array of steps in an Arcade in the order they would appear during
// autoplay. Steps may repeat.
function getStepOrder(steps, maxNumberOfSteps) {
    var sequence = [];
    var nextStep = getFirstNonEmptyStep(steps);
    while (!!nextStep && sequence.length < maxNumberOfSteps) {
        sequence.push(nextStep);
        nextStep = getNextNonEmptyAutoStep(steps, nextStep, false);
    }
    return sequence;
}
exports.getStepOrder = getStepOrder;
