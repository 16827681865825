import { Flow } from 'src/types'

import { AccountMixin } from '../AccountCore'
import { Workspace } from './workspace'

export interface Flows {
  isCreator: (flow: Flow) => boolean
  isCollaborator: (flow: Flow) => boolean
  mayEdit: (flow: Flow) => boolean
  mayToggleFlowPublished: (flow: Flow) => boolean
  belongsToUserTeam: (flow: Flow) => boolean
  mayPreview: (flow: Flow) => boolean
  mayRequestEditAccess: (flow: Flow) => boolean
  mayViewInsights: (flow: Flow) => boolean
  showInsightsPaywall: boolean
  mayShare: (flow: Flow) => boolean
  publishingLimitDoesApply: boolean
  publishingLimit: number
  mayPublishAdditionalFlows: (personalPublishedFlowsCount: number) => boolean
  mayUseImageEditor: boolean
  mayUsePanAndZoom: boolean
  showCustomVariables: boolean
  mayUseCustomVariables: boolean
  folderFor: (flow: Flow) => { name: string; path: string }
  mayUseAutoplay: boolean
  mayUseChooseYourOwnAdventure: boolean
}

export const flows: AccountMixin<Flows> = (
  core,
  { userProfile, plan, team },
  // TODO fix workspace hydration
  workspace: Workspace
) => ({
  isCreator(flow) {
    return flow.createdBy === core.userId
  },

  isCollaborator(flow) {
    return flow.editors?.includes(core.userId)
  },

  mayEdit(flow) {
    return (
      this.isCreator(flow) ||
      this.isCollaborator(flow) ||
      (this.belongsToUserTeam(flow) &&
        !team?.features?.['Edit Access to Arcades is Controlled'] &&
        !!userProfile.isActiveMemberOfTeam &&
        userProfile.activeTeamId === flow.group)
    )
  },

  mayToggleFlowPublished(flow) {
    return flow.createdBy === core.userId
  },

  belongsToUserTeam(flow) {
    return (
      flow.belongsToTeam &&
      (flow.group === core.userEmailGroup ||
        (workspace.userIsExternalMember &&
          flow.group === workspace.domain &&
          flow.belongsToTeam))
    )
  },

  mayPreview(flow) {
    return this.mayEdit(flow) || this.belongsToUserTeam(flow)
  },

  mayRequestEditAccess(flow) {
    return (
      !this.mayEdit(flow) &&
      this.mayPreview(flow) &&
      flow.belongsToTeam &&
      !!team?.features?.['Edit Access to Arcades is Controlled']
    )
  },

  mayViewInsights(flow) {
    return this.mayEdit(flow) || (this.mayPreview(flow) && flow.belongsToTeam)
  },

  get showInsightsPaywall() {
    if (plan.startsWith('Growth')) return false
    if (userProfile.features?.['Flow Insights']) return false
    return true
  },

  mayShare(flow) {
    return this.mayPreview(flow)
  },

  get publishingLimitDoesApply() {
    return core.freePlanLimitsApply
  },

  get publishingLimit() {
    return 3
  },

  mayPublishAdditionalFlows(personalPublishedFlowsCount: number) {
    if (this.publishingLimitDoesApply) {
      return personalPublishedFlowsCount < this.publishingLimit
    }
    return true
  },

  get mayUseImageEditor() {
    return !plan.startsWith('Free')
  },

  get mayUsePanAndZoom() {
    return plan.startsWith('Growth')
  },

  get mayUseAutoplay() {
    return !plan.startsWith('Free')
  },

  get mayUseChooseYourOwnAdventure() {
    return plan.startsWith('Growth')
  },

  get showCustomVariables(): boolean {
    return !!team?.features?.['Custom Variables']
  },

  get mayUseCustomVariables(): boolean {
    return plan.startsWith('Growth') && this.showCustomVariables
  },

  folderFor(flow) {
    if (this.belongsToUserTeam(flow) && !this.isCollaborator(flow)) {
      return {
        // TODO should point to workspace.displayName, but workspace is not hydrated
        name: team?.name ?? '',
        path: '/flows/team',
      }
    }
    if (this.isCollaborator(flow)) {
      return {
        name: 'Shared with me',
        path: '/flows/shared',
      }
    }
    return {
      name: 'My Arcades',
      path: '/flows',
    }
  },
})
