"use strict";
exports.__esModule = true;
exports.getWatermarkDisplayProps = void 0;
var types_1 = require("../types");
var getWatermarkDisplayProps = function (flow, flowCreatorUserProfile, flowTeam) {
    var _a;
    if (flowCreatorUserProfile === void 0) { flowCreatorUserProfile = null; }
    if (flowTeam === void 0) { flowTeam = null; }
    if (flowCreatorUserProfile === null && flowTeam === null) {
        return {
            shouldDisplay: true,
            customProps: undefined
        };
    }
    if (flowTeam === null || flowTeam === void 0 ? void 0 : flowTeam.currentSubscriber) {
        // Team exists and is allowed to remove/change watermarks
        if (flow.belongsToTeam) {
            // Flow is in "Team" folder
            if (flowTeam.features[types_1.Feature.NoArcadeBranding]) {
                // Team has turned off the watermark globally
                return {
                    shouldDisplay: false
                };
            }
            else {
                // Team has not turned off the watermark globally,
                // and may have supplied custom props
                return {
                    shouldDisplay: true,
                    customProps: flowTeam.watermark
                };
            }
        }
        else {
            // Flow is in "My Arcades" folder
            if (flow.showArcadeButton === null) {
                // Flow-level watermark setting has not been set
                if ((flowCreatorUserProfile === null || flowCreatorUserProfile === void 0 ? void 0 : flowCreatorUserProfile.activeTeamId) === flowTeam.id) {
                    // User is an active member of the team
                    if (flowTeam.features[types_1.Feature.NoArcadeBranding]) {
                        // Team has turned off the watermark globally
                        return {
                            shouldDisplay: false
                        };
                    }
                    else {
                        // Team has not turned off the watermark globally,
                        // and may have supplied custom props
                        return {
                            shouldDisplay: true,
                            customProps: undefined
                        };
                    }
                }
                else {
                    // User is not an active member of the team
                    return {
                        shouldDisplay: true
                    };
                }
            }
            else if (flow.showArcadeButton === true) {
                // Flow-level watermark is explicitly enabled (TODO: how?)
                return {
                    shouldDisplay: true,
                    customProps: undefined
                };
            }
            else {
                // Flow-level watermark is explicitly disabled (TODO: how?)
                return {
                    shouldDisplay: false
                };
            }
        }
    }
    // Team doesn't exist or isn't allowed to remove/change watermarks
    if (flowCreatorUserProfile === null || flowCreatorUserProfile === void 0 ? void 0 : flowCreatorUserProfile.currentSubscriber) {
        // Creator is a Pro user
        if (flow.showArcadeButton === null) {
            // Flow-level watermark setting has not been set
            if ((_a = flowCreatorUserProfile.features) === null || _a === void 0 ? void 0 : _a[types_1.Feature.NoArcadeBranding]) {
                // Pro user has turned off the watermark globally (TODO: how?)
                return {
                    shouldDisplay: false
                };
            }
            else {
                // Pro user has not turned off the watermark globally
                return {
                    shouldDisplay: true,
                    customProps: undefined
                };
            }
        }
        else if (flow.showArcadeButton === true) {
            // Flow-level watermark is explicitly enabled
            return {
                shouldDisplay: true,
                customProps: undefined
            };
        }
        else {
            // Flow-level watermark is explicitly disabled
            return {
                shouldDisplay: false
            };
        }
    }
    // Creator is a Free user
    return {
        shouldDisplay: true,
        customProps: undefined
    };
};
exports.getWatermarkDisplayProps = getWatermarkDisplayProps;
