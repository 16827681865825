import { createContext, PropsWithChildren } from 'react'
import { Flow } from 'src/types'

import { CurrentFlowProps } from './types'

// see src/flow/withFlow.tsx for details

export const FlowContext = createContext<CurrentFlowProps>({
  // this is the wrong type but it doesn't matter because FlowProvider is
  // initializing the flow with the server's data
  flow: {} as Flow,
  isReadonly: true,
  hasBackground: false,
  hasWrapper: false,
  hasOverlay: false,
  fontFamily: '',

  // selection
  activeStep: null,
  activeStepIndex: -1,
  setActiveStep: () => {},
})

export function FlowProvider(
  props: PropsWithChildren<{ contextProps: CurrentFlowProps }>
) {
  const { children, contextProps } = props

  return (
    <FlowContext.Provider value={contextProps}>{children}</FlowContext.Provider>
  )
}
